// /src/service/localStorageService.js

export function loadStateFromLocalStorage(key) {
  try {
    const serializedState = localStorage.getItem(key);
    return serializedState ? JSON.parse(serializedState) : undefined;
  } catch (error) {
    console.error('Failed to load state from localStorage:', error);
    return undefined;
  }
}
  
export function saveStateToLocalStorage(key, state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (error) {
    console.error('Failed to save state to localStorage:', error);
  }
}
  