// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

// Guest Views
import Login from '@/views/Login.vue';
import ForgotPassword from '@/views/ForgotPasswordPage.vue';
import RegisterPage from '@/views/Register.vue';
import VerifyUser from '@/views/VerifyUser.vue';
import WelcomePage from '@/views/WelcomePage.vue';

// Seller Views
import SellerDashboard from '@/views/Sellers/Dashboard.vue';
import MySellerProfile from '@/views/Sellers/SellerPersonalData.vue';
import MyItems from '@/views/Sellers/MyItems.vue';
import SearchBazaars from '@/views/Sellers/SearchBazaars.vue';

// Organizer Views
import OrganizerDashboard from '@/views/Organizers/Dashboard.vue';
import MyOrganizerProfile from '@/views/Organizers/OrganizerPersonalData.vue';
import ManageBazaars from '@/views/Organizers/ManageBazaars.vue';
import ManageSellers from '@/views/Organizers/ManageSellers.vue';
import BazaarSettings from '@/views/Organizers/Settings.vue';
import Reports from '@/views/Organizers/Reports.vue';

const routes = [
  { path: '/', name: 'Welcome', component: WelcomePage },
  { path: '/login', name: 'Login', component: Login },
  { path: '/register', name: 'Register', component: RegisterPage },
  { path: '/verify', name: 'VerifyUser', component: VerifyUser },
  { path: '/forgot-password', name: 'ForgotPassword', component: ForgotPassword },
  // Seller Routes
  { path: '/sellers/dashboard', name: 'SellerDashboard', component: SellerDashboard, meta: { role: 'seller' } },
  { path: '/sellers/my-seller-profile', name: 'MySellerProfile', component: MySellerProfile, meta: { role: 'seller' } },
  { path: '/sellers/my-items', name: 'MyItems', component: MyItems, meta: { role: 'seller' } },
  { path: '/sellers/search-bazaars', name: 'SearchBazaars', component: SearchBazaars, meta: { role: 'seller' } },
  // Organizer Routes
  { path: '/organizers/dashboard', name: 'OrganizerDashboard', component: OrganizerDashboard, meta: { role: 'organizer' } },
  { path: '/organizers/my-organizer-profile', name: 'MyOrganizerProfile', component: MyOrganizerProfile, meta: { role: 'organizer' } },
  { path: '/organizers/manage-bazaars', name: 'ManageBazaars', component: ManageBazaars, meta: { role: 'organizer' } },
  { path: '/organizers/manage-sellers', name: 'ManageSellers', component: ManageSellers, meta: { role: 'organizer' } },
  { path: '/organizers/settings', name: 'BazaarSettings', component: BazaarSettings, meta: { role: 'organizer' } },
  { path: '/organizers/reports', name: 'Reports', component: Reports, meta: { role: 'organizer' } },
  // Catch-all route
  { path: '/:pathMatch(.*)*', redirect: '/' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authToken = store.getters['auth/getAuthToken']; // Access namespaced getter
  const userRole = store.getters['auth/getUserRole'];  // Access namespaced getter

  // Routes that require authentication
  if (to.meta.role) {
    if (!authToken) {
      // No token present, redirect to login
      return next('/login');
    }

    try {
      // Attempt token refresh if needed
      await store.dispatch('auth/refreshToken'); // Ensure token is valid/updated
    } catch (error) {
      console.error('Token refresh failed:', error.message);
      store.dispatch('auth/logout'); // Clear stale data
      return next('/login'); // Redirect to login on failure
    }

    if (to.meta.role !== userRole) {
      // Role mismatch, redirect to login
      return next('/login');
    }
  }

  next(); // Proceed to the requested route
});


export default router;
