import { createApp, reactive } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
//import database from '@/services/databaseService';
import { createI18n } from 'vue-i18n';
import de from './locales/de.json';
import '@/assets/styles.css';
import 'bootstrap/dist/css/bootstrap.css';

import { OhVueIcon, addIcons } from "oh-vue-icons";
import { FaArrowUp } from "oh-vue-icons/icons/fa";
import {
  IoCloudOfflineOutline,
  IoCloudOutline,
  IoCloudUploadOutline,
} from "oh-vue-icons/icons/io";

addIcons(FaArrowUp, IoCloudOfflineOutline, IoCloudOutline, IoCloudUploadOutline);

const i18n = createI18n({
  locale: 'de',
  messages: { de },
});


// Initialize SQLite database
/*database.init().then(() => {
  console.log('Database ready');
}).catch((err) => {
  console.error('Database initialization failed', err);
});*/

const app = createApp(App);

// Global reactive state for login
app.provide('loginState', reactive({ isLoggedIn: !!localStorage.getItem('role') }));

app.use(i18n);

app.component("v-icon", OhVueIcon);

try {
  // Fetch the CSRF token before mounting the app
  // await store.dispatch('fetchCsrfToken');
} catch (error) {
  console.error('Error during app initialization:', error);
}
app.use(store).use(router).mount('#app');

