import { makeApiCall } from "./apiWrapper";

// API functions
export const fetchUserDetails = async () => {
  return await makeApiCall("getUserDetails.php", "GET");
};

export const updateUserDetails = async (userDetails) => {
  return await makeApiCall("updateUserDetails.php", "POST", userDetails);
};

export const changeUserPassword = async (passwordData) => {
  return await makeApiCall("changeUserPassword.php", "POST", passwordData);
};

export const verifyUser = async (token) => {
  return await makeApiCall("verifyUser.php", "POST", { token });
};

export const requestSellerNumber = async (id) => {
  return await makeApiCall("requestSellerNumber.php", "POST", { id });
};

// Fetch user ZIP code using `fetchUserDetails`
export const fetchUserZip = async () => {
  try {
    const userDetails = await fetchUserDetails();
    if (userDetails.success && userDetails.userDetails) {
      return userDetails.userDetails.zip || null; // Return ZIP if available, otherwise null
    } else {
      console.warn("User details not found or ZIP code is missing.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user ZIP code:", error.message);
    throw error;
  }
};
