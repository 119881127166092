import { createStore } from 'vuex';
import auth from './modules/auth';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from '@/services/localStorageService';

// Load persisted state
const persistedState = loadStateFromLocalStorage('vuex');

const store = createStore({
  modules: {
    auth: {
      ...auth,
      state: {
        ...auth.state,
        authToken: persistedState?.auth?.authToken || auth.state.authToken,
        role: persistedState?.auth?.role || auth.state.role,
      },
    },
  },
});

// Persist state changes to localStorage
store.subscribe((mutation, state) => {
  saveStateToLocalStorage('vuex', {
    auth: state.auth,
  });
});


export default store;
