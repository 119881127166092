<template>
  <div class="container">
    <h1 class="my-4 text-center text-md-start">Einstellungen für Basare</h1>

    <!-- Select Bazaar -->
    <div class="mb-4">
      <label for="bazaarSelect" class="form-label">Wählen Sie einen Basar aus:</label>
      <select
        id="bazaarSelect"
        v-model="selectedBazaarId"
        @change="fetchBazaarSettings"
        class="form-select"
      >
        <option value="" disabled>Bitte wählen...</option>
        <option v-for="bazaar in bazaars" :key="bazaar.id" :value="bazaar.id">
          {{ bazaar.bazaar_name }} ({{ bazaar.start_date }})
        </option>
      </select>
    </div>

    <!-- Settings Form -->
    <div v-if="loading" class="text-center">
      <p>Lädt Einstellungen...</p>
    </div>
    <div v-else-if="selectedBazaarId">
      <h3 class="mb-4">Einstellungen für {{ selectedBazaarName  }}</h3>
      <form @submit.prevent="saveSettings">
        <div class="list-group mb-3">
          <!-- Auto Assign New Seller Number -->
          <div class="list-group-item d-flex align-items-center">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="autoAssignSellerNumberNew"
                v-model="bazaarSettings.auto_assign_seller_number_new"
              />
              <label
                class="form-check-label ms-3 text-wrap"
                for="autoAssignSellerNumberNew"
              >
                Neue Verkäufernummern automatisch zuweisen
              </label>
            </div>
          </div>

          <!-- Auto Assign Existing Seller Number -->
          <div class="list-group-item d-flex align-items-center">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="autoAssignSellerNumberExisting"
                v-model="bazaarSettings.auto_assign_seller_number_existing"
              />
              <label
                class="form-check-label ms-3 text-wrap"
                for="autoAssignSellerNumberExisting"
              >
                Vorhandene Verkäufernummern automatisch aktivieren
              </label>
            </div>
          </div>
        </div>

        <!-- Save Button -->
        <div class="text-center text-md-start">
          <button type="submit" class="btn btn-primary">Einstellungen speichern</button>
        </div>
      </form>
    </div>
    <div v-else>
      <p>Bitte wählen Sie einen Basar aus, um die Einstellungen zu bearbeiten.</p>
    </div>

    <!-- Message Modal -->
    <MessageModal
      ref="messageModal"
      :title="modalTitle"
      :message="modalMessage"
      :alertType="modalAlertType"
      confirmText="OK"
      :showCancel="false"
      @confirm="closeModal"
    />

    <!-- Toast Notification -->
    <div aria-live="polite" aria-atomic="true" class="d-flex justify-content-center align-items-center w-100">
      <div
        class="ms-auto toast-container position-fixed top-0 p-3"
        style="z-index: 1100"
      >
        <div
          class="toast"
          ref="toast"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div class="toast-header bg-success">
            <strong class="me-auto">{{ toastTitle }}</strong>
            <small class="text-muted">Jetzt</small>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
          <div class="toast-body">
            {{ toastMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageModal from "@/components/MessageModal.vue";
import { fetchBazaars } from "@/api-connectors/bazaar";
import { fetchBazaarSettings, updateBazaarSettings } from "@/api-connectors/settings";
import { Toast } from "bootstrap";

export default {
  components: {
    MessageModal,
  },
  data() {
    return {
      bazaars: [],
      selectedBazaarId: "",
      selectedBazaarName: "", // New variable to track the selected bazaar name
      bazaarSettings: {
        auto_assign_seller_number_new: false,
        auto_assign_seller_number_existing: false,
      },
      loading: false,
      modalTitle: "",
      modalMessage: "",
      modalAlertType: "",
      toastTitle: "",
      toastMessage: "",
    };
  },
  methods: {
    async fetchBazaars() {
      try {
        const response = await fetchBazaars();
        if (response.success) {
          this.bazaars = response.bazaars;
        } else {
          this.showModal("Fehler", "Fehler beim Abrufen der Basarliste.", "danger");
        }
      } catch (error) {
        this.showModal("Fehler", "Ein unerwarteter Fehler ist aufgetreten.", "danger");
      }
    },
    async fetchBazaarSettings() {
      if (!this.selectedBazaarId) return;
      this.loading = true;

      // Find the selected bazaar name based on the selected ID
      const selectedBazaar = this.bazaars.find(bazaar => bazaar.id === this.selectedBazaarId);
      this.selectedBazaarName = selectedBazaar ? selectedBazaar.bazaar_name : "Unbekannt";

      try {
        const response = await fetchBazaarSettings(this.selectedBazaarId);
        if (response.success && response.settings) {
          // Update properties directly to maintain reactivity
          this.bazaarSettings = {
            auto_assign_seller_number_new: Boolean(response.settings.auto_assign_seller_number_new),
            auto_assign_seller_number_existing: Boolean(response.settings.auto_assign_seller_number_existing),
          };
        } else {
          this.showModal("Fehler", "Fehler beim Abrufen der Einstellungen.", "danger");
        }
      } catch (error) {
        this.showModal("Fehler", "Ein unerwarteter Fehler ist aufgetreten.", "danger");
      } finally {
        this.loading = false;
      }
    },
    async saveSettings() {
      try {
        const response = await updateBazaarSettings(this.selectedBazaarId, this.bazaarSettings);
        if (response.success) {
          this.showToast("Erfolg", "Die Einstellungen wurden erfolgreich gespeichert.");
        } else {
          this.showModal("Fehler", "Fehler beim Speichern der Einstellungen.", "danger");
        }
      } catch (error) {
        this.showModal("Fehler", "Ein unerwarteter Fehler ist aufgetreten.", "danger");
        console.error("Error saving settings:", error.message);
      }
    },
    showModal(title, message, alertType) {
      this.modalTitle = title;
      this.modalMessage = message;
      this.modalAlertType = alertType;
      this.$refs.messageModal.open();
    },
    showToast(title, message) {
      this.toastTitle = title;
      this.toastMessage = message;

      const toastEl = this.$refs.toast;
      const toastInstance = new Toast(toastEl);
      toastInstance.show();
    },
    closeModal() {
      this.$refs.messageModal.close();
    },
  },
  async mounted() {
    await this.fetchBazaars();
  },
};
</script>

<style scoped>
.container {
  max-width: 600px;
}
</style>
