<template>
  <div class="container">
    <h1 class="my-4 text-center text-md-start">Basare Verwalten</h1>

    <!-- Expandable Create Bazaar Section -->
    <div class="card mb-4">
      <div class="card-header text-center text-md-start">
        <button
          class="btn btn-link text-decoration-none"
          @click="toggleCreateForm"
        >
          Formular: Neuer Basar
        </button>
      </div>
      <div v-if="showForm" class="card-body">
        <h3>Neue Veranstaltung</h3>
        <p>Bitte geben Sie die Daten des Basars an. Diese werden auf der Homepage veröffentlicht, sodass Verkäufer ihren Basar auch über die Suchfunktion finden können.</p>
        <h4>Veranstaltungsort</h4>

        <form @submit.prevent="createBazaar">
          <div class="row g-3">
            <div class="col-md-6">
              <label for="bazaarName" class="form-label">Bezeichnung</label>
              <input
                id="bazaarName"
                v-model="formData.bazaar_name"
                type="text"
                class="form-control"
                placeholder="z.B. Gemeindehalle Bärstadt"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="bazaarStreet" class="form-label">Straße</label>
              <input
                id="bazaarStreet"
                v-model="formData.bazaar_street"
                type="text"
                class="form-control"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="bazaarHousenumber" class="form-label">Hausnummer</label>
              <input
                id="bazaarHousenumber"
                v-model="formData.bazaar_housenumber"
                type="text"
                class="form-control"
                required
              />
            </div>            
            <div class="col-md-6">
              <label for="bazaarZip" class="form-label"
                >PLZ</label
              >
              <input
                id="bazaarZip"
                v-model="formData.bazaar_zip"
                type="text"
                class="form-control"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="bazaarCity" class="form-label">Stadt / Gemeinde</label>
              <input
                id="bazaarCity"
                v-model="formData.bazaar_city"
                type="text"
                class="form-control"
                required
              />
            </div>
          </div>
          <hr class="my-4"></hr>
          <h4>Einstellungen</h4>
          <p>Die Einstellungen unten können solange noch verändert werden, bis der erste Verkäufer eine Verkäufernummer erhalten hat.</p>
          <div class="row g-3">
            <div class="col-md-6">
              <label for="date" class="form-label">Startdatum</label>
              <input
                id="date"
                v-model="formData.start_date"
                type="date"
                class="form-control"
                required
              />
            </div>
            <div class="col">
              <label for="maxSellers" class="form-label">Max. Verkäufer</label>
              <input
                id="maxSellers"
                v-model="formData.max_sellers"
                type="number"
                class="form-control"
                required
              />
            </div>
            <div class="col">
              <label for="commission" class="form-label">Kommission (%)</label>
              <input
                id="commission"
                v-model="formData.commission"
                type="number"
                class="form-control"
                required
              />
            </div>            
            <div class="col-md-6">
              <label for="maxItems" class="form-label"
                >Max. Artikel pro Verkäufer</label
              >
              <input
                id="maxItems"
                v-model="formData.max_items_per_seller"
                type="number"
                class="form-control"
                required
              />
            </div>
            <div class="col">
              <label for="fee" class="form-label">Startgebühr (€)</label>
              <input
                id="fee"
                v-model="formData.starting_fee"
                type="number"
                class="form-control"
                required
              />
            </div>
            <div class="col">
              <label for="minPrice" class="form-label">Mindestpreis (€)</label>
              <input
                id="minPrice"
                v-model="formData.min_item_price"
                type="number"
                class="form-control"
                required
              />
            </div>
          </div>
          <div class="mt-3 text-center text-md-start">
            <button type="submit" class="btn btn-success">Speichern</button>
            <button
              @click="toggleCreateForm"
              type="button"
              class="btn btn-secondary"
            >
              Abbrechen
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- Bazaar List -->
    <h2 class="text-center text-md-start">Meine Basare</h2>
    <div v-if="loading" class="text-center">
      <p>Lädt Basare...</p>
    </div>
    <div v-else class="table-responsive">
      <table class="table table-bordered table-hover">
        <thead class="table-light">
          <tr>
            <th>Status</th>
            <th>Startdatum</th>
            <th class="d-none d-md-table-cell">Kommission (%)</th>
            <th class="d-none d-md-table-cell">Mindestpreis (€)</th>
            <th class="d-none d-md-table-cell">Startgebühr (€)</th>
            <th class="d-none d-md-table-cell">Max. Verkäufer</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="bazaar in bazaars" :key="bazaar.id">
            <td>{{ bazaar.registration_closed ? "Geschlossen" : "Offen" }}</td>
            <td>{{ bazaar.start_date }}</td>
            <td class="d-none d-md-table-cell">{{ bazaar.commission }}</td>
            <td class="d-none d-md-table-cell">{{ bazaar.min_item_price }}</td>
            <td class="d-none d-md-table-cell">{{ bazaar.starting_fee }}</td>
            <td class="d-none d-md-table-cell">{{ bazaar.max_sellers }}</td>
            <td>
              <div>
                <select
                  v-model="bazaar.action"
                  class="form-select mb-2"
                >
                  <option value="0" disabled>Bitte wählen</option>
                  <option value="editBazaar">Basar bearbeiten</option>
                  <option value="viewSellers">Verkäufer anzeigen</option>
                  <option value="viewReport">Bericht anzeigen</option>
                  <!-- Toggle Registration Option -->
                  <option
                    :value="'toggleRegistration'"
                    :class="bazaar.registration_closed ? 'bg-success' : 'bg-warning'"
                  >
                    {{ bazaar.registration_closed ? "Registrierung öffnen" : "Registrierung schließen" }}
                  </option>
                  <option value="deleteBazaar" class="bg-danger">Basar löschen</option>
                </select>
                <button
                  @click="executeAction(bazaar.id)"
                  class="btn btn-primary w-100"
                >
                  Ausführen
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal Error OK Only -->
    <MessageModal
      ref="modalErrorMsg"
      :title="modalErrorMsg.title"
      :message="modalErrorMsg.message"
      :alertType="modalErrorMsg.alertType"
      :showCancel="modalErrorMsg.showCancel"
      :showConfirm="modalErrorMsg.showConfirm"
      :confirmText="modalErrorMsg.confirmText"
      :cancelText="modalErrorMsg.cancelText"
      @confirm="handleCloseErrorMsg"
    />

    <!-- Modal confirm deleteBazaar-->
    <MessageModal
      ref="modalDeleteBazaar"
      :title="modalDeleteBazaar.title"
      :message="modalDeleteBazaar.message"
      :alertType="modalDeleteBazaar.alertType"
      :showCancel="modalDeleteBazaar.showCancel"
      :showConfirm="modalDeleteBazaar.showConfirm"
      :confirmText="modalDeleteBazaar.confirmText"
      @confirm="handleConfirmDeleteBazzar"
    />

    <EditBazaarModal
      v-show="selectedBazaar !== null"
      :bazaar="selectedBazaar"
      ref="editModal"
      @save="saveBazaar"
      @close="selectedBazaar = null"
    />

    <!-- Toast Notification -->
    <div aria-live="polite" aria-atomic="true" class="d-flex justify-content-center align-items-center w-100">
      <div
        class="ms-auto toast-container position-fixed top-0 p-3"
        style="z-index: 1100"
      >
        <div
          class="toast"
          ref="toast"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div class="toast-header bg-success">
            <strong class="me-auto">{{ toastTitle }}</strong>
            <small class="text-muted">Jetzt</small>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
          <div class="toast-body">
            {{ toastMessage }}
          </div>
        </div>
      </div>
    </div>

    <!-- Back to Top Button -->
    <button
      v-if="showBackToTop"
      id="back-to-top"
      class="btn btn-primary position-fixed"
      style="bottom: 20px; right: 20px; z-index: 1000"
      @click="scrollToTop"
    >
      <v-icon name="fa-arrow-up" />
    </button>
  </div>
</template>

<script>
import { useStore, ref } from "vuex"; // Importing store for accessing state
import EditBazaarModal from "@/components/EditBazaarModal.vue";
import MessageModal from "@/components/MessageModal.vue";
import { Toast } from "bootstrap";
import {
  createBazaar,
  updateBazaar,
  deleteBazaar,
  fetchBazaars,
  fetchSellersInBazaar,
  fetchItemsInBazaar,
  toggleRegistration,
  viewReport,
} from "@/api-connectors/bazaar";

export default {
  components: {
    EditBazaarModal,
    MessageModal,
  },
  data() {
    return {
      loading: true,
      modalErrorMsg: {
        title: "",
        message: "",
        alertType: "",
        showCancel: false,
        showConfirm: true,
        confirmText: "OK",
        cancelText: "",
      },
      modalDeleteBazaar: {
        title: "",
        message: "",
        alertType: "",
        showCancel: true,
        showConfirm: true,
        confirmText: "OK",
        cancelText: "Abbrechen",
      },
      toastTitle: "",
      toastMessage: "",
      bazaars: [],
      sellers: [],
      showForm: false,
      formData: {
        bazaar_name: "",
        bazaar_street: "",
        bazaar_housenumber: "",
        bazaar_zip: "",
        bazaar_city: "",      
        start_date: "",
        max_sellers: 10,
        max_items_per_seller: 20,
        commission: 10,
        starting_fee: 5,
        min_item_price: 1,
      },
      selectedBazaar: null,
      items: [],
      view: "report", // 'sellers' or 'report'
      selectedAction: {}, // Tracks selected action for each bazaar
      showBackToTop: false, // Tracks visibility of the "Back to Top" button
    };
  },
  methods: {
    toggleCreateForm() {
      this.showForm = !this.showForm;
    },
    initializeSelectedAction() {
      this.bazaars.forEach((bazaar) => {
        if (!bazaar.action) {
          bazaar.action = "0"; // Add default action if missing
        }
      });
    },
    async executeAction(bazaar_id) {
      const bazaar = this.bazaars.find((b) => b.id === bazaar_id);
      if (!bazaar || bazaar.action === "0") {
        this.openModalOkOnly("Fehler", "Bitte wählen Sie eine Aktion aus.", "warning");
        return;
      }

      if (bazaar.action === "editBazaar") {
        this.openEditModal(bazaar_id);
      } else if (bazaar.action === "viewSellers") {
        this.viewSellers(bazaar_id);
      } else if (bazaar.action === "viewReport") {
        this.viewReport(bazaar_id);
      } else if (bazaar.action === "toggleRegistration") {
        this.toggleRegistration(bazaar_id);
      } else if (bazaar.action === "deleteBazaar") {
        this.confirmDeleteBazaar(bazaar_id);
      }

      // Reset dropdown after action
      bazaar.action = "0";
    },
    async fetchBazaars() {
      try {
        const result = await fetchBazaars();
        if (result.success) {
          this.bazaars = result.bazaars.map((bazaar) => ({
            ...bazaar,
            action: "0", // Add action property
          }));
           console.log("Bazaars populated:", this.bazaars); // Debugging
        } else {
          this.openModalOkOnly(
            "Fehler",
            result.message || "Fehler beim Abrufen der Basare.",
            "danger"
          );
        }
      } catch (error) {
        this.openModalOkOnly(
          "Fehler",
          "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
          "danger"
        );
        console.error("Error fetching bazaars:", error);
        } finally {
        this.loading = false; // Mark loading as complete
      }
    },
    async createBazaar() {
      try {
        const result = await createBazaar(this.formData);
        if (result.success) {
          this.showToast("Erfolg", "Basar erfolgreich erstellt!");
          this.fetchBazaars();
          this.showForm = false;
        } else {
          this.openModalOkOnly(
            "Fehler",
            result.message || "Fehler beim Erstellen des Basars.",
            "danger"
          );
        }
      } catch (error) {
        this.openModalOkOnly(
          "Fehler",
          error.message || "Fehler beim Erstellen des Basars.",
          "danger"
        );
      }
    },
    async saveBazaar(updatedBazaar) {
      try {
        const result = await updateBazaar(updatedBazaar);
        if (result.success) {
          this.showToast("Erfolg", "Basar erfolgreich aktualisiert!");
          this.fetchBazaars();
        } else {
          this.openModalOkOnly(
            "Fehler",
            "Fehler beim Aktualisieren des Basars.",
            "danger"
          );
        }
      } catch (error) {
        this.openModalOkOnly(
          "Fehler",
          "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
          "danger"
        );
        console.error("Error updating bazaar:", error);
      }
    },
    confirmDeleteBazaar(bazaar_id) {
      const bazaar = this.bazaars.find((b) => b.id === bazaar_id);
      if (bazaar) {
        this.selectedBazaar = { ...bazaar};
        this.openModalWithCancel(
          "Warnung",
          "Achtung, Sie sind dabei, einen Basar zu löschen. Verkäufer, die bereits Artikel für diesen Basar haben, behalten diese, aber ihre Verkäufernummer wird widerrufen. Fortfahren?",
          "danger"
        );
      } else {
        console.error("Bazaar not found:", bazaar_id);
      }
    },
    async handleDeleteBazaar(bazaar_id) {
      try {
        const result = await deleteBazaar(
          bazaar_id
        );
        if (result.success) {
          this.showToast("Erfolg", "Basar erfolgreich gelöscht!");
          this.fetchBazaars();
        } else {
          this.openModalOkOnly(
            "Fehler", 
            "Fehler beim Löschen des Basars.", 
            "danger"
          );
        }
      } catch (error) {
        this.openModalOkOnly(
          "Fehler", 
          "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.", 
          "danger"
        );
      }
    },
    async viewSellers(bazaar_id) {
      try {
        const result = await fetchSellersInBazaar(
          bazaar_id
        );
        if (result.success) {
          this.sellers = result.sellers;
          this.view = "sellers";
        } else {
          this.openModalOkOnly(
            "Fehler",
            "Fehler beim Abrufen der Verkäufer.",
            "danger"
          );
        }
      } catch (error) {
        this.openModalOkOnly(
          "Fehler",
          "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
          "danger"
        );
      }
    },
    async viewReport(bazaar_id) {
      try {
        const result = await viewReport(
          bazaar_id
        );
        if (result.success) {
          this.items = result.items;
          this.view = "report";
        } else {
          this.openModalOkOnly(
            "Fehler",
            result.message || "Fehler beim Abrufen des Berichts.",
            "danger"
          );
        }
      } catch (error) {
        this.openModalOkOnly(
          "Fehler",
          "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
          "danger"
        );
        console.error("Error viewing report:", error);
      }
    },
    async toggleRegistration(bazaar_id) {
      const bazaar = this.bazaars.find((b) => b.id === bazaar_id);

      if (!bazaar) {
        this.openModalOkOnly("Fehler", "Basar nicht gefunden.", "danger");
        return;
      }

      const today = new Date();
      const startDate = new Date(bazaar.start_date);

      if (!bazaar.registration_closed) {
        // Trying to open the registration
        if (startDate <= today) {
          // Opening is not allowed for expired bazaars
          this.openModalOkOnly(
            "Öffnen nicht möglich",
            "Der Basar kann nicht geöffnet werden, da das Startdatum in der Vergangenheit liegt.",
            "warning"
          );
          return;
        }
      }

      try {
        // Call the API to toggle registration status
        const result = await toggleRegistration(
          bazaar_id,
          !bazaar.registration_closed // Toggle the status
        );

        if (result.success) {
          const action = bazaar.registration_closed ? "geöffnet" : "geschlossen";
          this.showToast("Erfolg", `Registrierung erfolgreich ${action}!`);
          this.fetchBazaars();
        } else {
          this.openModalOkOnly(
            "Fehler",
            result.message || "Fehler beim Umschalten der Registrierung.",
            "danger"
          );
        }
      } catch (error) {
        console.error("Error in toggleRegistration:", error);
        this.openModalOkOnly(
          "Fehler",
          "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
          "danger"
        );
      }
    }, 
    openEditModal(bazaar_id) {
      const bazaar = this.bazaars.find((b) => b.id === bazaar_id);
      if (bazaar) {
        this.selectedBazaar = { ...bazaar };
        this.$nextTick(() => {
          this.$refs.editModal.openModal();
        });
      } else {
        console.error("Bazaar not found:", bazaar_id);
      }
    },
    openModalOkOnly(title, message, alertType = "danger") {
      this.modalErrorMsg.title = title;
      this.modalErrorMsg.message = message;
      this.modalErrorMsg.alertType = alertType;
      this.$refs.modalErrorMsg.open();
    },
    openModalWithCancel(title, message, alertType = "danger") {
      this.modalDeleteBazaar.title = title;
      this.modalDeleteBazaar.message = message;
      this.modalDeleteBazaar.alertType = alertType;
      this.$refs.modalDeleteBazaar.open();
    },
    async handleConfirmDeleteBazzar() {
      this.$refs.modalDeleteBazaar.close();
      await this.handleDeleteBazaar(this.selectedBazaar.id);
      this.fetchBazaars();
      this.showToast("Erfolg", "Basar erfolgreich gelöscht!");
    },
    handleCloseErrorMsg() {
      this.$refs.modalErrorMsg.close();
    },
    showToast(title, message) {
      this.toastTitle = title;
      this.toastMessage = message;

      // Initialize and show the Bootstrap Toast
      const toastEl = this.$refs.toast;
      const toastInstance = new Toast(toastEl);
      toastInstance.show();
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    checkScroll() {
      this.showBackToTop = window.scrollY > 200;
    },
  },
  mounted() {
    this.fetchBazaars();
    window.addEventListener("scroll", this.checkScroll);
  },
  watch: {
    bazaars: {
      immediate: true,
      handler(newBazaars) {
        this.initializeSelectedAction(); // Initialize dropdowns when bazaars are updated
      },
    },
  },
  updated() {},
  beforeUnmount() {
    window.removeEventListener("scroll", this.checkScroll);
  },
};
</script>
