<template>
  <div
    v-if="item"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    ref="modal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Artikel bearbeiten</h5>
          <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleSave">
            <div class="mb-3">
              <label for="itemName" class="form-label">Artikelname</label>
              <input
                id="itemName"
                v-model="editedItem.name"
                type="text"
                class="form-control"
                placeholder="Name des Artikels"
                required
              />
            </div>
            <div class="row g-3">
              <div class="col-md-6">
                <label for="itemPrice" class="form-label">Preis (€)</label>
                <input
                  id="itemPrice"
                  v-model="editedItem.price"
                  type="number"
                  class="form-control"
                  placeholder="Preis"
                  required
                />
              </div>
              <div class="col-md-6">
                <label for="itemSize" class="form-label">Größe</label>
                <input
                  id="itemSize"
                  v-model="editedItem.size"
                  type="text"
                  class="form-control"
                  placeholder="Größe"
                />
              </div>
            </div>
            <div class="mt-3 text-center">
              <button type="submit" class="btn btn-success">Speichern</button>
              <button type="button" class="btn btn-secondary ms-2" @click="closeModal">Abbrechen</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        name: "",
        price: 0,
        size: "",
      }),
    },
  },
  data() {
    return {
      editedItem: {},
    };
  },
  methods: {
    openModal() {
      const modal = new Modal(this.$refs.modal);
      modal.show();
    },
    closeModal() {
      const modal = Modal.getInstance(this.$refs.modal);
      modal.hide();
      this.$emit("close");
    },
    handleSave() {
      this.$emit("save", { ...this.editedItem });
      this.closeModal();
    },
  },
  watch: {
    item: {
      immediate: true,
      handler(newItem) {
        this.editedItem = { ...newItem };
      },
    },
  },
};
</script>

<style scoped>
.modal-content {
  max-width: 500px;
  margin: auto;
}
</style>
