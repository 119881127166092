import { openDB } from 'idb';

const DATABASE_NAME = 'basareno';
const DATABASE_VERSION = 1;

let db;

export const initDB = async () => {
  db = await openDB(DATABASE_NAME, DATABASE_VERSION, {
    upgrade(database) {
      if (!database.objectStoreNames.contains('items')) {
        const itemsStore = database.createObjectStore('items', {
          keyPath: 'id',
          autoIncrement: true,
        });
        itemsStore.createIndex('bazaar_id', 'bazaar_id', { unique: false });
        itemsStore.createIndex('synced', 'synced', { unique: false });
      }
    },
  });
};

// Open the IndexedDB
const dbPromise = openDB(DATABASE_NAME, DATABASE_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('items')) {
        db.createObjectStore('items', { keyPath: 'id', autoIncrement: true });
      }
    },
  });

export const addItemToDB = async (item) => {
  const tx = db.transaction('items', 'readwrite');
  await tx.objectStore('items').add(item);
  await tx.done;
};

export const updateItemInDB = async (item) => {
    try {
      const db = await openDB(DATABASE_NAME, DATABASE_VERSION); // Assuming the DB name is "basarenoDB"
      const tx = db.transaction("items", "readwrite");
      const store = tx.objectStore("items");
  
      const existingItem = await store.get(item.id);
      if (!existingItem) {
        throw new Error(`Item with ID ${item.id} does not exist.`);
      }
  
      await store.put(item); // Update the existing item
      await tx.done;
  
      console.log(`Item with ID ${item.id} updated successfully.`);
      return true;
    } catch (error) {
      console.error("Error updating item in IndexedDB:", error);
      throw error;
    }
};

// Function to get unsynced items
export async function getUnsyncedItems() {
  try {
    const db = await dbPromise;
    const tx = db.transaction('items', 'readonly');
    const store = tx.objectStore('items');
    const allItems = await store.getAll();

    // Parse JSON strings if data was stored as strings
    const parsedItems = allItems.map((item) =>
      typeof item === 'string' ? JSON.parse(item) : item
    );

    // Filter unsynced items
    return parsedItems.filter((item) => !item.synced);
  } catch (error) {
    console.error('Error fetching unsynced items:', error.message);
    throw error;
  }
}

export const getAllItems = async () => {
  const tx = db.transaction('items', 'readonly');
  return await tx.objectStore('items').getAll();
};

export const markItemAsSynced = async (id) => {
  const tx = db.transaction('items', 'readwrite');
  const item = await tx.objectStore('items').get(id);
  item.synced = true;
  await tx.objectStore('items').put(item);
  await tx.done;
};
