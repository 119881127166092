<template>
  <div id="app">
    <Navbar @showLogoutModal="openLogoutModal" />
    <router-view />

    <!-- Reusable Message Modal in App.vue -->
    <MessageModal
      ref="logoutModal"
      title="Abmeldung Bestätigen"
      message="Sind Sie sicher, dass Sie sich abmelden möchten?"
      alertType="warning"
      confirmText="Abmelden"
      cancelText="Abbrechen"
      @confirm="handleLogout"
      @close="handleModalClose"
    />
    <p></p>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import MessageModal from "@/components/MessageModal.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Navbar,
    MessageModal,
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    openLogoutModal() {
      this.$refs.logoutModal.open();
    },
    async handleLogout() {
      await this.logout();
      this.$router.push("/"); // Redirect to homepage after logout
    },
    handleModalClose() {
      console.log("Modal closed");
    },
  },
};
</script>
