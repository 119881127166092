<template>
  <div class="container">
    <h1 class="my-4 text-center">Basare Suchen</h1>

    <!-- Search Input -->
    <input
      v-model="searchTerm"
      @input="fetchAndDisplayBazaars"
      placeholder="Suchen nach Veranstalter, Stadt oder PLZ"
      class="form-control mb-3"
    />

    <!-- Loading Placeholder -->
    <div v-if="loading" class="text-center my-4">
      <p>Lädt Basare...</p>
    </div>

    <!-- Bazaar List Table -->
    <div v-else class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="d-none d-sm-table-cell">Startdatum</th>
            <th>Veranstalter</th>
            <th class="d-none d-sm-table-cell">Stadt</th>
            <th class="d-none d-sm-table-cell">PLZ</th>
            <th class="d-none d-sm-table-cell">Status</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="bazaar in bazaars"
            :key="bazaar.id"
            :class="{
              'bg-light-green': !bazaar.registration_closed,
              'bg-light-grey': bazaar.registration_closed,
            }"
          >
            <td class="d-none d-sm-table-cell">{{ bazaar.start_date }}</td>
            <td>{{ bazaar.bazaar_name }}</td>
            <td class="d-none d-sm-table-cell">{{ bazaar.bazaar_city }}</td>
            <td class="d-none d-sm-table-cell">{{ bazaar.bazaar_zip }}</td>
            <td class="d-none d-sm-table-cell">
              {{ bazaar.registration_closed ? 'Geschlossen' : 'Offen' }}
            </td>
            <td class="text-center">
              <div>
                <select v-model="bazaar.action" class="form-select mb-2">
                  <option value="0" disabled>Aktion auswählen</option>
                  <option value="requestSellerNumber">Verkäufernummer anfordern</option>
                  <option value="activateSellerNumber">Meine Verkäufernummer aktivieren</option>
                </select>
                <button
                  @click="executeAction(bazaar)"
                  class="btn btn-primary btn-sm mx-auto d-block"
                >
                  Ausführen
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

<!-- Message Modal -->
    <MessageModal
      ref="messageModal"
      :title="modalTitle"
      :message="modalMessage"
      :alertType="modalAlertType"
      confirmText="OK"
      :showCancel="false"
      @confirm="closeModal"
    />

    <!-- Toast Notification -->
    <div aria-live="polite" aria-atomic="true" class="d-flex justify-content-center align-items-center w-100">
      <div
        class="ms-auto toast-container position-fixed top-0 p-3"
        style="z-index: 1100"
      >
        <div
          class="toast"
          ref="toast"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div class="toast-header bg-success">
            <strong class="me-auto">{{ toastTitle }}</strong>
            <small class="text-muted">Jetzt</small>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
          <div class="toast-body">
            {{ toastMessage }}
          </div>
        </div>
      </div>
    </div>
      
  </div>
</template>

<script>
import { fetchUserZip, requestSellerNumber } from "@/api-connectors/user";
import { searchBazaars } from "@/api-connectors/bazaar";
import MessageModal from "@/components/MessageModal.vue";
import { Toast } from "bootstrap";

export default {
  components: {
    MessageModal,
  },
  data() {
    return {
      searchTerm: "",
      bazaars: [],
      userZip: null,
      loading: true, // Track loading state
      modalTitle: "",
      modalMessage: "",
      modalAlertType: "",
      toastTitle: "",
      toastMessage: "",
    };
  },
  methods: {
    async fetchUserZip() {
      try {
        this.userZip = await fetchUserZip();
      } catch (error) {
        console.error("Error fetching user ZIP code:", error.message);
      }
    },
    async fetchAndDisplayBazaars(initialLoad = false) {
      if (initialLoad) {
        this.loading = true; // Show placeholder during initial load
      }

      try {
        const result = await searchBazaars(this.searchTerm, this.userZip);
        if (result.success) {
          this.bazaars = result.bazaars.map((bazaar) => ({
            ...bazaar,
            action: "0", // Initialize action dropdown
          }));
        } else {
          console.error("Error fetching bazaars:", result.message);
        }
      } catch (error) {
        console.error("Error searching bazaars:", error.message);
      } finally {
        this.loading = false; // Hide placeholder after fetching
      }
    },
    async executeAction(bazaar) {
      if (!bazaar.action || bazaar.action === "0") {
        alert("Bitte wählen Sie eine Aktion aus.");
        return;
      }

      if (bazaar.action === "requestSellerNumber") {
        this.requestSellerNumber(bazaar);
      } else if (bazaar.action === "activateSellerNumber") {
        this.activateSellerNumber(bazaar);
      }

      bazaar.action = "0"; // Reset action after execution
    },
    async requestSellerNumber(bazaar) {
      try {
        const response = await requestSellerNumber(bazaar.id);
        if (response.success) {
          this.showToast(
            "Erfolg",
            `Verkäufernummer erfolgreich zugewiesen: ${response.seller_number}`
          );
        } else {
          this.showModal("Fehler", response.message, "danger");
        }
      } catch (error) {
        this.showModal("Fehler", "Ein unerwarteter Fehler ist aufgetreten.", "danger");
        console.error("Error requesting seller number:", error.message);
      }
    },
    async activateSellerNumber(bazaar) {
      alert(`Verkäufernummer für Basar ID: ${bazaar.id} aktivieren`);
      // Add API integration here
    },
    showModal(title, message, alertType) {
      this.modalTitle = title;
      this.modalMessage = message;
      this.modalAlertType = alertType;
      this.$refs.messageModal.open();
    },
    showToast(title, message) {
      this.toastTitle = title;
      this.toastMessage = message;

      const toastEl = this.$refs.toast;
      const toastInstance = new Toast(toastEl);
      toastInstance.show();
    },
    closeModal() {
      this.$refs.messageModal.close();
    },    
  },
  async mounted() {
    await this.fetchUserZip(); // Fetch user ZIP on load
    await this.fetchAndDisplayBazaars(true); // Fetch initial bazaars
  },
};
</script>

<style scoped>
.bg-light-green {
  background-color: #d4edda; /* Light green for open bazaars */
}
.bg-light-grey {
  background-color: #e9ecef; /* Light grey for closed bazaars */
}
.table-responsive {
  overflow-x: auto; /* Horizontal scrolling for mobile devices */
}
.text-center {
  text-align: center;
}
</style>
