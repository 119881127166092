import { makeApiCall } from "./apiWrapper";

// Helper function to validate bazaar start date
const validateStartDate = (startDate) => {
  const currentDate = new Date(); // Current date and time
  const providedDate = new Date(startDate); // Convert startDate to Date object

  if (providedDate <= currentDate) {
    throw new Error("Das Startdatum des Basars muss in der Zukunft liegen.");
  }
};

// API functions

export const createBazaar = async (bazaarData) => {
  // Validate start date
  validateStartDate(bazaarData.start_date);

  // Proceed with API call if validation passes
  return await makeApiCall(`createBazaar.php`, "POST", bazaarData);
};

// Search bazaars with keyword and proximity to user ZIP
export const searchBazaars = async (searchTerm, userZip) => {
  return await makeApiCall(`searchBazaars.php`, "POST", { searchTerm, userZip });
};

export const updateBazaar = async (formData) => {
  return await makeApiCall(`updateBazaar.php`, "POST", formData);
};

// Fetch bazaars for an organizer_id
export const fetchBazaars = async () => {
  return await makeApiCall(`getBazaars.php`, "GET");
};

// Delete a bazaar of an organizer_id
export const deleteBazaar = async (id) => {
  return await makeApiCall(`deleteBazaar.php`, "DELETE", { id });
};


export const fetchSellersInBazaar = async (id) => {
  return await makeApiCall(`getSellersInBazaar.php`, "POST", { id });
};

export const fetchItemsInBazaar = async (id) => {
  return await makeApiCall(`getItemsInBazaar.php`, "POST", { id });
};

// Toggle registration for an bazaars id
export const toggleRegistration = async (id, registration_closed) => {
  return await makeApiCall(`toggleRegistration.php`, "POST", {
    id,
    registration_closed,
  });
};

// Fetch all seller numbers of a signle bazaars id
export const viewSellers = async (id) => {
  return await makeApiCall(`getSellersInBazaar.php`, "POST", { id });
};

export const viewReport = async (id) => {
  return await makeApiCall(`getItemsInBazaar.php`, "POST", { id });
};
