<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-light bg-light">
    <div class="container-fluid">
          <a class="navbar-brand" href="#">Basareno</a>
                  <!-- Sync Status Icon -->
        <div class="sync-status ms-auto d-flex align-items-center p-2">
          <v-icon
            :name="syncIcon"
            class="fs-4"
            :title="syncTooltip"
            style="cursor: pointer;"
          />
        </div>

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="true"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse text-center" id="navbarNav">
        <ul class="navbar-nav me-auto">
          <li v-for="item in menu" :key="item.label" class="nav-item">
            <router-link 
              class="nav-link" 
              :to="item.to" 
              @click="collapseNavbar"
            >
              {{ item.label }}
            </router-link>
          </li>
        </ul>
        <!-- Logout Button -->
        <button
          v-if="isLoggedIn"
          class="btn btn-danger ms-auto mb-2"
          @click="askLogout"
        >
          Abmelden
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import menus from "@/components/menus";
import { IoCloudOfflineOutline, IoCloudOutline, IoCloudUploadOutline } from "oh-vue-icons/icons";

export default {
  data() {
    return {
      isOnline: navigator.onLine, // Initial online state
      syncState: "synced", // Possible values: "offline", "synced", "syncing"
    };
  },
  computed: {
    ...mapGetters("auth", ["getUserRole"]), // Specify "auth" namespace for mapGetters
    menu() {
      return menus[this.getUserRole] || menus.guest;
    },
    isLoggedIn() {
      return this.getUserRole !== "guest";
    },
    syncIcon() {
      // Return the appropriate icon for the current sync state
      if (!this.isOnline) return "io-cloud-offline-outline";
      if (this.syncState === "syncing") return "io-cloud-upload-outline";
      return "io-cloud-outline";
    },
    syncTooltip() {
      // Return a tooltip text based on sync state
      if (!this.isOnline) return "Offline";
      if (this.syncState === "syncing") return "Synchronisiere...";
      return "Online und synchronisiert";
    },
  },
  methods: {
    askLogout() {
      this.collapseNavbar();
      this.$emit('showLogoutModal');
    },
    collapseNavbar() {
      // Get the navbar collapse element
      const navbarCollapse = document.getElementById("navbarNav");
      if (navbarCollapse && navbarCollapse.classList.contains("show")) {
        navbarCollapse.classList.remove("show");
      }
    },
  },  
  mounted() {
    // Listen for online/offline events
    window.addEventListener("online", () => {
      this.isOnline = true;
      this.syncState = "synced"; // Assume synced when back online
    });
    window.addEventListener("offline", () => {
      this.isOnline = false;
    });
  },  
  beforeUnmount() {
    // Remove event listeners
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOfflineStatus);
  },  
};
</script>
<style scoped>
.sync-status i {
  font-size: 1.5rem;
  color: white;
}
</style>