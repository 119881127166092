<template>
  <div class="container d-flex flex-column align-items-center justify-content-center min-vh-10">
    <h1 class="mb-4">Passwort vergessen</h1>
    <form @submit.prevent="submitForgotPassword" class="w-100" style="max-width: 400px;">
      <!-- Email Field -->
      <div class="mb-3">
        <label for="email" class="form-label">E-Mail-Adresse</label>
        <input
          id="email"
          v-model="email"
          type="email"
          class="form-control text-center"
          placeholder="Geben Sie Ihre E-Mail-Adresse ein"
          required
        />
      </div>
      <!-- Submit Button -->
      <div class="d-grid">
        <button type="submit" class="btn btn-primary">Reset-Link anfordern</button>
      </div>
    </form>
    <!-- Message Modal -->
    <MessageModal
      ref="messageModal"
      :title="modalTitle"
      :message="modalMessage"
      :alertType="modalAlertType"
      confirmText="OK"
      :showCancel="false"
      @confirm="closeModal"
    />
  </div>
</template>

<script>
import MessageModal from "@/components/MessageModal.vue";
import { API_BASE } from "../config";

export default {
  components: { MessageModal },
  data() {
    return {
      email: "",
      modalTitle: "",
      modalMessage: "",
      modalAlertType: "info",
    };
  },
  methods: {
    async submitForgotPassword() {
      try {
        const response = await fetch(`${API_BASE}/requestPasswordReset.php`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: this.email }),
        });
        const data = await response.json();
        if (data.success) {
          this.modalTitle = "Erfolg";
          this.modalMessage =
            "Ein Reset-Link wurde an Ihre E-Mail-Adresse gesendet.";
          this.modalAlertType = "success";
        } else {
          throw new Error(data.message || "Fehler beim Anfordern des Reset-Links.");
        }
      } catch (error) {
        this.modalTitle = "Fehler";
        this.modalMessage = error.message;
        this.modalAlertType = "danger";
      }
      this.$refs.messageModal.open();
    },
    closeModal() {
      this.email = ""; // Reset email field
    },
  },
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
}
</style>
