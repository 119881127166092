<template>
  <div class="container">
    <h1 class="my-4 text-center text-md-start">Verkäufer Verwalten</h1>

    <!-- Select Bazaar -->
    <div class="mb-4">
      <label for="bazaarSelect" class="form-label">Wählen Sie einen Basar</label>
      <select
        id="bazaarSelect"
        v-model="selectedBazaarId"
        class="form-select"
        @change="fetchSellers"
      >
        <option disabled value="">Bitte wählen</option>
        <option v-for="bazaar in bazaars" :key="bazaar.id" :value="bazaar.id">
          {{ bazaar.bazaar_name }} ({{ bazaar.start_date }})
        </option>
      </select>
    </div>

    <!-- Sellers Table -->
    <div v-if="loading" class="text-center">
      <p>Lädt Verkäufer...</p>
    </div>
    <div v-else class="table-responsive">
      <table class="table table-bordered table-hover">
        <thead class="table-light">
          <tr>
            <th>Verkäufernummer</th>
            <th>Name</th>
            <th>Email</th>
            <th>Telefon</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="seller in sellers" :key="seller.id">
            <td>{{ seller.seller_number }}</td>
            <td>{{ seller.name }}</td>
            <td>{{ seller.email }}</td>
            <td>{{ seller.phone }}</td>
            <td>
              <button class="btn btn-danger btn-sm" @click="deleteSeller(seller.id)">
                Verkäufer Löschen
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal for Error Messages -->
    <MessageModal
      ref="errorModal"
      :title="errorModal.title"
      :message="errorModal.message"
      :alertType="errorModal.alertType"
      confirmText="OK"
      :showCancel="false"
      @confirm="closeErrorModal"
    />
  </div>
</template>

<script>
import { fetchBazaars, fetchSellersInBazaar } from "@/api-connectors/bazaar";
import MessageModal from "@/components/MessageModal.vue";

export default {
  components: {
    MessageModal,
  },
  data() {
    return {
      bazaars: [], // List of all bazaars
      selectedBazaarId: "", // Currently selected bazaar ID
      sellers: [], // Sellers of the selected bazaar
      loading: false, // Loading state for sellers
      errorModal: {
        title: "",
        message: "",
        alertType: "danger",
      },
    };
  },
  methods: {
    async fetchBazaars() {
      try {
        const result = await fetchBazaars();
        if (result.success) {
          this.bazaars = result.bazaars;
        } else {
          this.showErrorModal(
            "Fehler",
            result.message || "Fehler beim Abrufen der Basare."
          );
        }
      } catch (error) {
        console.error("Error fetching bazaars:", error.message);
        this.showErrorModal(
          "Fehler",
          "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut."
        );
      }
    },
    async fetchSellers() {
      if (!this.selectedBazaarId) {
        return;
      }

      this.loading = true;

      try {
        const result = await fetchSellersInBazaar(this.selectedBazaarId);
        if (result.success) {
          this.sellers = result.sellers;
        } else {
          this.showErrorModal(
            "Fehler",
            result.message || "Fehler beim Abrufen der Verkäufer."
          );
        }
      } catch (error) {
        console.error("Error fetching sellers:", error.message);
        this.showErrorModal(
          "Fehler",
          "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut."
        );
      } finally {
        this.loading = false;
      }
    },
    deleteSeller(sellerId) {
      // Placeholder for future implementation
      console.log(`Delete seller with ID: ${sellerId}`);
    },
    showErrorModal(title, message) {
      this.errorModal.title = title;
      this.errorModal.message = message;
      this.$refs.errorModal.open();
    },
    closeErrorModal() {
      this.$refs.errorModal.close();
    },
  },
  mounted() {
    this.fetchBazaars(); // Fetch the list of bazaars on page load
  },
};
</script>

<style scoped>
/* Additional styles for the ManageSellers page */
</style>
