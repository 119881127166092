import { getUnsyncedItems, markItemAsSynced } from './indexedDBService';
import { makeApiCall } from '@/api-connectors/apiWrapper';
import { saveItem } from "@/api-connectors/seller";

export const syncService = {
  isSyncing: false,

  async syncItems() {
    try {
      const unsyncedItems = await getUnsyncedItems();
      if (!unsyncedItems.length) {
        console.log("No items to sync.");
        return;
      }

      this.isSyncing = true;

      for (const item of unsyncedItems) {
        const result = await saveItem(item);
        if (result.success) {
          await markItemAsSynced(item.id); // Mark item as synced in IndexedDB
        } else {
          console.error("Failed to sync item:", item.id);
        }
      }

      console.log("All items synced successfully!");
    } catch (error) {
      console.error("Error syncing items:", error.message);
    } finally {
      this.isSyncing = false;
    }
  },
  async pushItemToServer(item) {
    try {
      // Send the item to the server
      const response = await makeApiCall("saveItem.php", "POST", item);

      // Check if the sync was successful
      if (response.success) {
        console.log(`Item with ID ${item.id} synced successfully.`);
        return { success: true, message: "Item synced successfully." };
      } else {
        console.error(`Failed to sync item with ID ${item.id}:`, response.message);
        return { success: false, message: response.message || "Unknown sync error." };
      }
    } catch (error) {
      console.error("Error pushing item to server:", error.message);
      return { success: false, message: "Network or server error." };
    }
  },
};
