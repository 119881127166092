import { API_BASE } from '@/config';

export default {
  namespaced: true, // Enables namespacing for better modularity
  state: {
    authToken: '', // JWT for authentication
    role: 'guest', // Default user role
  },
  getters: {
    getAuthToken(state) {
      return state.authToken;
    },
    getUserRole(state) {
      return state.role;
    },
  },
  mutations: {
    setAuthToken(state, token) {
      state.authToken = token;
    },
    setUserRole(state, role) {
      state.role = role;
    },
    clearAuth(state) {
      state.authToken = '';
      state.role = 'guest';
    },
  },
  actions: {
    async login({ commit }, { email, password }) {
      try {
        const response = await fetch(`${API_BASE}/login.php`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, password }),
        });
        const data = await response.json();
        if (data.success) {
          commit('setAuthToken', data.token);
          commit('setUserRole', data.role);
          localStorage.setItem('refreshToken', data.refreshToken); // Save refresh token
          return data;
        } else {
          throw new Error(data.message || 'Login failed');
        }
      } catch (error) {
        console.error('Login error:', error.message);
        throw error;
      }
    },

    async refreshToken({ commit }) {
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        throw new Error('No refresh token available');
      }

      try {
        const response = await fetch(`${API_BASE}/refreshToken.php`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ refreshToken }),
        });
        const data = await response.json();
        if (data.success) {
          commit('setAuthToken', data.token);
          return data;
        } else {
          throw new Error(data.message || 'Token refresh failed');
        }
      } catch (error) {
        console.error('Refresh token error:', error.message);
        throw error;
      }
    },

    async logout({ commit }) {
      commit('clearAuth'); // Clear token and role
      localStorage.removeItem('refreshToken'); // Remove refresh token
    },
  },
};
