import { API_BASE } from "../config";
import store from "@/store";


// Helper function to refresh token
const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
  
    if (!refreshToken) {
      throw new Error("No refresh token available. Please log in again.");
    }
  
    try {
      const result = await store.dispatch("auth/refreshToken");
  
      if (!result.success) {
        throw new Error(result.message || "Failed to refresh the access token.");
      }
  
      // Update the Vuex store with the new token
      store.commit("auth/setAuthToken", result.token);
      return result.token;
    } catch (error) {
      console.error("Error refreshing token:", error.message);
      throw error;
    }
  };  

/**
 * Helper function to check response status and throw errors if needed.
 */
const handleResponse = async (response) => {
  const result = await response.json();
  if (!response.ok) {
    throw new Error(result.message || "Ein Fehler ist aufgetreten.");
  }
  return result;
};

/**
 * API Wrapper function for making API calls with token management.
 * @param {string} endpoint - The API endpoint (e.g., 'getBazaars.php').
 * @param {string} method - The HTTP method (e.g., 'GET', 'POST').
 * @param {object} [body] - The request body (for POST/PUT methods).
 */
export const makeApiCall = async (endpoint, method = "GET", body = null) => {
  let authToken = store.getters.getAuthToken;

  // Attempt to refresh token if it has expired
  if (!authToken || isTokenExpired(authToken)) {
    try {
      authToken = await refreshAccessToken();
    } catch (error) {
      console.error("Token Refresh Failed:", error.message);
      throw new Error("Bitte melden Sie sich erneut an.");
    }
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };

  const options = {
    method,
    headers,
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(`${API_BASE}/${endpoint}`, options);
    return await handleResponse(response);
  } catch (error) {
    console.error("API Call Failed:", error.message);
    throw error;
  }
};

/**
 * Helper function to check if a token is expired.
 * @param {string} token - JWT token.
 */
export const isTokenExpired = (token) => {
  try {
    const { exp } = JSON.parse(atob(token.split(".")[1]));
    return Date.now() >= exp * 1000; // Compare current time to token expiration
  } catch (error) {
    console.error("Error decoding token:", error.message);
    return true; // Treat as expired if decoding fails
  }
};
