<template>
  <div class="container">
    <h1 class="my-4 text-center text-md-start">Meine Artikel</h1>

    <!-- Add Item Section -->
    <div class="card mb-4">
      <div class="card-body">
        <form @submit.prevent="addItem">
          <div class="mb-3">
            <label for="itemName" class="form-label">Artikelname</label>
            <input
              id="itemName"
              v-model="formData.name"
              type="text"
              class="form-control"
              placeholder="Name des Artikels"
              required
            />
          </div>
          <div class="row g-3">
            <div class="col-md-6">
              <label for="itemPrice" class="form-label">Preis (€)</label>
              <input
                id="itemPrice"
                v-model="formData.price"
                type="number"
                class="form-control"
                placeholder="Preis"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="itemSize" class="form-label">Größe</label>
              <input
                id="itemSize"
                v-model="formData.size"
                type="text"
                class="form-control"
                placeholder="Größe"
              />
            </div>
          </div>
          <div class="mt-3 text-center text-md-start">
            <button type="submit" class="btn btn-success">Artikel hinzufügen</button>
          </div>
        </form>
      </div>
    </div>

    <!-- Item List -->
    <h2 class="text-center text-md-start">Meine Artikel</h2>
    <div v-if="loading" class="text-center">
      <p>Lädt Artikel...</p>
    </div>
    <div v-else class="table-responsive">
      <table class="table table-bordered table-hover">
        <thead class="table-light">
          <tr>
            <th>Artikelname</th>
            <th>Preis (€)</th>
            <th>Größe</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="items.length === 0">
            <td colspan="4" class="text-center">Keine Artikel vorhanden. Fangen Sie an, einen hinzuzufügen!</td>
          </tr>
          <tr v-for="item in items" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.price }}</td>
            <td>{{ item.size }}</td>
            <td>
              <div>
                <select v-model="item.action" class="form-select mb-2">
                  <option value="0" disabled>Bitte wählen</option>
                  <option value="editItem">Bearbeiten</option>
                  <option value="deleteItem">Löschen</option>
                </select>
                <button @click="executeAction(item)" class="btn btn-primary w-100">
                  Ausführen
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Toast Notification -->
    <div aria-live="polite" aria-atomic="true" class="d-flex justify-content-center align-items-center w-100">
      <div class="ms-auto toast-container position-fixed top-0 p-3" style="z-index: 1100">
        <div class="toast" ref="toast" role="alert" aria-live="assertive" aria-atomic="true">
          <div class="toast-header bg-success">
            <strong class="me-auto">{{ toastTitle }}</strong>
            <small class="text-muted">Jetzt</small>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
          <div class="toast-body">
            {{ toastMessage }}
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Item Modal -->
    <EditItemModal
      :item="selectedItem"
      ref="editItemModal"
      @save="saveItem"
      @close="selectedItem = null"
    />

    <!-- Message Modal -->
    <MessageModal
      ref="messageModal"
      :title="modalTitle"
      :message="modalMessage"
      :alertType="modalAlertType"
      confirmText="OK"
      :showCancel="false"
      @confirm="closeModal"
    />
  </div>
</template>

<script>
import MessageModal from "@/components/MessageModal.vue";
import EditItemModal from "@/components/EditItemModal.vue";
import { Toast } from "bootstrap";
import { fetchItems, saveItem, deleteItem } from "@/api-connectors/seller";
import { initDB, addItemToDB, getAllItems, updateItemInDB } from "@/services/indexedDBService";
import { syncService } from "@/services/syncService";


export default {
  components: {
    MessageModal,
    EditItemModal,
  },
  data() {
    return {
      items: [],
      formData: {
        id: null,
        name: "",
        size: "",
        price: "",
        bazaar_id: "",
      },
      loading: false,
      offlineMode: false,
      syncing: false,
      toastTitle: "",
      toastMessage: "",
      modalTitle: "",
      modalMessage: "",
      modalAlertType: "",
      selectedItem: null,
    };
  },
  methods: {
    async fetchItems() {
      this.items = await getAllItems();
    },

    async saveItem(item) {
      try {
        const updatedItem = {
          ...item,
          updated_at: new Date().toISOString(), // Update timestamp
          synced: false, // Mark as unsynced
        };

        // Update in IndexedDB
        await updateItemInDB(updatedItem);

        // Check online status
        if (!navigator.onLine) {
          this.showToast(
            "Offline",
            "Änderungen gespeichert. Synchronisierung erfolgt bei Verbindung."
          );
        } else {
          // Sync with server
          const result = await syncService.pushItemToServer(updatedItem);
          if (result.success) {
            await markItemAsSynced(updatedItem.id); // Mark as synced in IndexedDB
            this.showToast("Erfolg", "Änderungen erfolgreich synchronisiert!");
          } else {
            throw new Error("Fehler bei der Synchronisierung.");
          }
        }

        this.fetchItems(); // Refresh items in the UI
      } catch (error) {
        console.error("Error saving item:", error.message);
        this.showModal("Fehler", "Ein unerwarteter Fehler ist aufgetreten.");
      }
    },
    async addItem() {
      const newItem = {
        ...this.formData,
        id: this.formData.id || Date.now(),
        synced: false,
      };

      await addItemToDB(newItem);
      this.showToast("Erfolg", "Artikel wurde lokal gespeichert!");
      this.fetchItems();

      if (navigator.onLine) {
        this.syncItems();
      }
    },
    async syncItems() {
      this.syncing = true;
      await syncService.syncItems();
      this.syncing = false;
      this.fetchItems(); // Refresh the item list
    },
    async executeAction(item) {
      if (item.action === "editItem") {
        this.editItem(item);
      } else if (item.action === "deleteItem") {
        await this.deleteItem(item.id);
      }
      item.action = "0"; // Reset action
    },
    async deleteItem(id) {
      try {
        const result = await deleteItem(id);
        if (result.success) {
          this.showToast("Erfolg", "Artikel erfolgreich gelöscht!");
          this.fetchItems();
        } else {
          this.showModal("Fehler", "Fehler beim Löschen des Artikels.");
        }
      } catch (error) {
        this.showModal("Fehler", "Ein unerwarteter Fehler ist aufgetreten.");
        console.error("Error deleting item:", error.message);
      }
    },
    editItem(item) {
      this.selectedItem = item; // Set the selected item
      this.$nextTick(() => {
        this.$refs.editItemModal.openModal(); // Open the modal
      });
    },
    resetForm() {
      this.formData = { id: null, name: "", size: "", price: "", bazaar_id: 1 };
    },
    showToast(title, message) {
      this.toastTitle = title;
      this.toastMessage = message;

      const toastEl = this.$refs.toast;
      const toastInstance = new Toast(toastEl);
      toastInstance.show();
    },
    showModal(title, message) {
      this.modalTitle = title;
      this.modalMessage = message;
      this.modalAlertType = "danger";
      this.$refs.messageModal.open();
    },
    closeModal() {
      this.$refs.messageModal.close();
    },
  },
  async mounted() {
    await initDB();
    this.fetchItems();

    if (navigator.onLine) {
      this.syncItems();
    }

    window.addEventListener("online", this.syncItems);
  },
  beforeUnmount() {
    window.removeEventListener("online", this.syncItems);
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
}
</style>
