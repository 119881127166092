import { makeApiCall } from "./apiWrapper";

// API functions
export const fetchItems = async () => {
  return await makeApiCall("getItems.php", "GET");
};

export const saveItem = async (formData) => {
  return await makeApiCall("saveItem.php", "POST", formData);
};

export const deleteItem = async (id) => {
  return await makeApiCall("deleteItem.php", "POST", { id });
};
