// src/config/menus.js
const menus = {
    guest: [
      { label: 'Startseite', to: '/' },
      { label: 'Anmelden', to: '/login' },
    ],
    seller: [
      { label: 'Dashboard', to: '/sellers/dashboard' },
      { label: 'Profil', to: '/sellers/my-seller-profile' },      
      { label: 'Meine Artikel', to: '/sellers/my-items' },
      { label: 'Basare durchsuchen', to: '/sellers/search-bazaars' },
    ],
    organizer: [
      { label: 'Dashboard', to: '/organizers/dashboard' },
      { label: 'Profil', to: '/organizers/my-organizer-profile' },
      { label: 'Meine Basare', to: '/organizers/manage-bazaars' },
      { label: 'Meine Verkäufer', to: '/organizers/manage-sellers' },
      { label: 'Einstellungen', to: '/organizers/settings' },
      { label: 'Berichte', to: '/organizers/reports' },
    ],
  };
  
  export default menus;
  